import { Modal, ModalHeader } from 'reactstrap';
import './styled.css';

export default function TicketDescription({ isOpen, toggle, urlTicket }) {
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size={'lg'}
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <iframe
          src={urlTicket}
          title={urlTicket}
          style={{ width: '100%', height: '100%' }}
        />
      </Modal>
    </>
  );
}
