import { useTranslation } from 'react-i18next';
export const PaidResponse = () => {
  const [t] = useTranslation();
  return (
    <section className='message-wrapper'>
      <h3>{t('response.paid.title')}</h3>
      <p>{t('response.paid.information')}</p>
    </section>
  );
};
