import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useCreditCardFlag } from '../../hooks/useCreditCardFlag';
import { useTranslation } from 'react-i18next';
import { useError } from '../../hooks/useError';

export const Token = ({
  cctoken,
  fillToken,
  amount,
  maxInstallments,
  installments,
  currentLanguage,
  setCurrentPrice,
}) => {
  const [t] = useTranslation();
  const { numberMask, brlMask, expireAtMask, onDelete, usdMask } = useMask();

  const countriesNew = t('payment.creditcard.country.options', {
    returnObjects: true,
  });
  const countries = countriesNew.sort((a, b) => {
    const codeA = a.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    const codeB = b.name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
    return codeA < codeB ? -1 : codeA > codeB ? 1 : 0;
  });
  const [disableInstallments, setDisableInstallments] = useState(true);

  const { flags } = useCreditCardFlag();
  const [flag, setFlag] = useState('DEFAULT');

  const [amountByInstallments, setAmountByInstallments] = useState([]);
  const { validateCreditCardNumber } = useError();

  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadprices = () => {
      const limit = maxInstallments;
      const prices = [];
      for (let i = 1; i <= limit; i++) {
        const newPrice = Math.round((installments[i] / i) * 100) / 100;
        const price = {
          installments: i,
          price: newPrice,
        };
        prices.push(price);
      }
      setAmountByInstallments(prices);
    };
    loadprices();
    return () => {};
  }, [amount]);

  return (
    <>
      <h3
        className='title2'
        style={{ marginTop: 20 }}
      >
        {t('payment.creditcard.title')}
      </h3>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-number`}
          >
            {t('payment.creditcard.number')}
            <span className='text-danger'>*</span>
          </label>
          <div className='creditcard-number-wrapper'>
            <input
              id={`cctoken-number`}
              value={cctoken.number}
              className='form-control'
              onChange={(e) => {
                const { value } = e.target;
                let newValue = numberMask(value);

                for (let key in flags) {
                  if (flags[key].pattern.test(newValue)) {
                    setFlag(key.toString());
                    newValue = flags[key].mask(newValue);
                    fillToken('number', newValue);
                    break;
                  }
                }
              }}
              onKeyDown={(e) => {
                const newValue = onDelete(e, cctoken.number);
                fillToken('number', newValue);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                let newValue = numberMask(value);
                const result = !validateCreditCardNumber(cctoken.number);
                if (result) {
                  setErrorMessage(t('fields.cctoken.number'));
                } else {
                  setErrorMessage();
                }
              }}
              autoComplete='off'
            />
            <img
              className='creditcard-flag'
              src={flags[flag].src}
              alt='Bandeira do cartão'
            />
          </div>
          <span
            id={`cctoken-number-error`}
            className='text-danger'
          />
        </Col>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-name`}
          >
            {t('payment.creditcard.holdername')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`cctoken-name`}
            value={cctoken.name}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              fillToken('name', value.toUpperCase());
            }}
            autoComplete='off'
          />
          <span
            id={`cctoken-name-error`}
            className='text-danger'
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-country`}
          >
            {t('payment.creditcard.country.name')}
            <span className='text-danger'>*</span>
          </label>
          <select
            id={`cctoken-country`}
            className='form-control select-style'
            autoComplete='off'
            value={cctoken.country}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              setDisableInstallments(!(newValue === 'BR'));
              if (!(newValue === 'BR')) {
                fillToken('installments', 1);
              }

              fillToken('country', newValue);
            }}
          >
            <option value={''}>{t('payment.creditcard.country.select')}</option>
            {countries.map((country, index) => (
              <option
                key={index}
                value={country.code}
              >
                {country.name}
              </option>
            ))}
          </select>
          <span
            id={`cctoken-country-error`}
            className='text-danger'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-expireAt`}
          >
            {t('payment.creditcard.expireDate')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`cctoken-expireAt`}
            placeholder='mm/aa'
            maxLength={5}
            value={cctoken.expireAt}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              let newValue = expireAtMask(value);
              fillToken('expireAt', newValue);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, cctoken.expireAt);
              fillToken('expireAt', newValue);
            }}
            autoComplete='off'
          />
          <span
            id={`cctoken-expireAt-error`}
            className='text-danger'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-code`}
          >
            {t('payment.creditcard.cvc')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`cctoken-code`}
            type='password'
            maxLength={4}
            value={cctoken.code}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = numberMask(value);
              fillToken('code', newValue);
            }}
            autoComplete='off'
          />
          <span
            id={`cctoken-code-error`}
            className='text-danger'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`token-installments`}
          >
            {t('payment.creditcard.installments')}
          </label>
          <select
            id={`token-installments`}
            className='form-control select-style'
            disabled={disableInstallments}
            value={cctoken.installments}
            onChange={(e) => {
              fillToken('installments', e.target.value);
              setCurrentPrice(installments[e.target.value]);
            }}
          >
            {amountByInstallments.map((price, index) => (
              <option
                key={index}
                value={price.installments}
              >
                {`${price.installments}x ${usdMask(price.price)}`}
              </option>
            ))}
          </select>
          <span
            id={`cctoken-installments-error`}
            className='text-danger'
          />
        </Col>
      </Row>
      <Row className='mt-2'></Row>
    </>
  );
};
