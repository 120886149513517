//styles
import 'bootstrap/dist/css/bootstrap.css';
import './assets/scss/theme.scss';
import './app.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { TransactionProvider } from './contexts/TransactionContext';

import { Cart } from './pages/cart';
import Payment from './pages/payment';
import { Finish } from './pages/finish';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  maxMessageLength: 10000,
  normalizeDepth: 10,
});

function App() {
  return (
    <TransactionProvider>
      <BrowserRouter>
        <Routes>
          <Route
            path='/ingressos'
            element={<Cart lng='pt' />}
          />
          <Route
            path='/es/tiquetes'
            element={<Cart lng='es' />}
          />
          <Route
            path='/en/tickets'
            element={<Cart lng='en' />}
          />
          <Route
            path='/pagamento'
            element={<Payment />}
          />
          <Route
            path='/finalizacao'
            element={<Finish />}
          />
        </Routes>
      </BrowserRouter>
    </TransactionProvider>
  );
}

export default App;
