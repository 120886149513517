export const useModelDefault = () => {
  const attendeeDefault = {
    name: '',
    document: '',
    birthdate: '',
    email: '',
    phone: '',
    foodrestriction: '',
    disability: '',
    changehotelperiod: false,
    hotelperiod: '',
    lgpdterm: false,
    communicationterm: false,
    language: 'pt',
    lastname: '',
    location: '',
    city: '',
    expertise: '',
    howdidyouknow: '',
    dentalmedicine: '',
    documenttype: '',
    childunder2: '',
    childname: '',
    childpassport: '',
    childbirthdate: '',
    recomendedby: '',
    bed: '',
  };

  const cctokenDefault = {
    number: '',
    name: '',
    expireAt: '',
    code: '',
    email: '',
    installments: 1,
    country: '',
  };

  const customerDefault = {
    name: '',
    email: '',
    address: '',
  };

  const transactionDefault = {
    promoCode: 'NOCODE',
    eventId: process.env.REACT_APP_EVENT_ID,
    eventCode: parseInt(process.env.REACT_APP_EVENT_ID_MICE),
    attendees: [],
    products: [],
    paymentType: 'pix',
    customer: null,
    token: '',
    installments: 1,
  };

  return {
    attendeeDefault,
    cctokenDefault,
    customerDefault,
    transactionDefault,
  };
};
