import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useTranslation } from 'react-i18next';
export const Attendee = ({ index, attendee, fillAttendee, length, cart }) => {
  const [t] = useTranslation();
  const countries = t('payment.attendee.countries', {
    returnObjects: true,
  });
  const expertises = t('payment.attendee.expertises', {
    returnObjects: true,
  });
  const dentalMedicineOption = t('payment.attendee.dentalmedicine.options', {
    returnObjects: true,
  });
  const documenttypes = t('payment.attendee.documenttype.options', {
    returnObjects: true,
  });

  const recommendedBy = t('payment.attendee.recomendedby', {
    returnObjects: true,
  });
  const children = t('payment.attendee.childunder2.options', {
    returnObjects: true,
  });
  const beds = t('payment.attendee.bed.options', {
    returnObjects: true,
  });
  const { alphabetMask, emailMask, mobileMask, onDelete, cnpjMask, cpfMask } =
    useMask();
  const [other, setOther] = useState(false);
  return (
    <>
      <h3
        className='title2'
        style={{ marginTop: '10px' }}
      >
        {t('payment.attendee.title.information')}{' '}
        {index === 0
          ? `${t('payment.attendee.title.attendee')}`
          : `${index}° ${t('payment.attendee.title.companion')}`}
      </h3>
      <Row className='mt-2 align-items-end'>
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-name`}
          >
            {t('payment.attendee.name')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-name`}
            className='form-control'
            autoComplete='off'
            value={attendee.name}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('name', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-name-error`}
            className='text-danger'
          />
        </Col>
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-lastname`}
          >
            {t('payment.attendee.lastname')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-lastname`}
            className='form-control'
            autoComplete='off'
            value={attendee.lastname}
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillAttendee('lastname', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-lastname-error`}
            className='text-danger'
          />
        </Col>
        {/* </Row>

      <Row className='mt-2 align-items-end'> */}
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-documenttype`}
          >
            {t('payment.attendee.documenttype.title')}
            <span className='text-danger'>*</span>
          </label>

          <select
            id={`attendee-${index}-documenttype`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.documenttype}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('documenttype', value, index);
            }}
          >
            {documenttypes.map((documenttype, index) => (
              <option
                key={index}
                value={documenttype.code}
              >
                {documenttype.name}
              </option>
            ))}
          </select>
          <span
            id={`attendee-${index}-documenttype-error`}
            className='text-danger'
          />
        </Col>
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-document`}
          >
            {t('payment.attendee.document')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-document`}
            className='form-control'
            autoComplete='off'
            value={attendee.document}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = '';
              if (attendee.documenttype === 'CPF') {
                newValue = cpfMask(value);
              }
              if (attendee.documenttype === 'CNPJ') {
                newValue = cnpjMask(value);
              }
              if (attendee.documenttype === 'PASSAPORTE') {
                newValue = value;
              }
              fillAttendee('document', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.document);
              fillAttendee('document', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-document-error`}
            className='text-danger'
          />
        </Col>

        {/* <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-birthdate`}
          >
            {t('payment.attendee.birthdate')}<span className='text-danger'>*</span>
          </label>
          <input
            type='date'
            min='1923-01-01'
            max='2024-12-31'
            id={`attendee-${index}-birthdate`}
            className='form-control'
            autoComplete='off'
            value={attendee.birthdate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('birthdate', newValue, index);
            }}
          />
        </Col> */}
        {/* </Row>
      <Row className='mt-2 align-items-end'> */}
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-recomendedby`}
          >
            {t('payment.attendee.location')}
            <span className='text-danger'>*</span>
          </label>
          {/* <input
            id={`attendee-${index}-location`}
            className='form-control'
            autoComplete='off'
            value={attendee.location}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('location', newValue, index);
            }}
          /> */}
          <select
            id={`attendee-${index}-location`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.location}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('location', value, index);
            }}
          >
            {countries.map((countries, index) => (
              <option
                key={index}
                value={countries.code}
              >
                {countries.name}
              </option>
            ))}
          </select>
          <span
            id={`attendee-${index}-location-error`}
            className='text-danger'
          />
        </Col>
        {/* <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-location`}
          >
            {t('payment.attendee.location')}<span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-location`}
            className='form-control'
            autoComplete='off'
            value={attendee.location}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('location', newValue, index);
            }}
          />
        </Col> */}
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-city`}
          >
            {t('payment.attendee.city')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-city`}
            className='form-control'
            autoComplete='off'
            value={attendee.city}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('city', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-city-error`}
            className='text-danger'
          />
        </Col>
        {/* </Row>
      <Row className='mt-2 align-items-end'> */}
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-email`}
          >
            {t('payment.attendee.email')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-email`}
            className='form-control'
            autoComplete='off'
            value={attendee.email}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = emailMask(value);
              fillAttendee('email', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-email-error`}
            className='text-danger'
          />
        </Col>
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-phone`}
          >
            {t('payment.attendee.phone')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-phone`}
            className='form-control'
            autoComplete='off'
            value={attendee.phone}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('phone', newValue, index);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, attendee.phone);
              fillAttendee('phone', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-phone-error`}
            className='text-danger'
          />
        </Col>
        {/* </Row>
      <Row className='mt-2 align-items-end'> */}
        {index > 0 && (
          <Col
            className='mt-2 align-items-end'
            md={6}
          >
            <label
              className='form-label'
              style={{ paddingLeft: 0 }}
              htmlFor={`attendee-${index}-dentalmedicine`}
            >
              {t('payment.attendee.dentalmedicine.title')}
              <span className='text-danger'>*</span>
            </label>

            <select
              id={`attendee-${index}-dentalmedicine`}
              className='form-control select-style'
              autoComplete='off'
              value={attendee.dentalmedicine}
              onChange={(e) => {
                const { value } = e.target;
                if (value === 'N') {
                  fillAttendee('expertise', '', index);
                }
                fillAttendee('dentalmedicine', value, index);
              }}
            >
              {dentalMedicineOption.map((dentalmedicine, index) => (
                <option
                  key={index}
                  value={dentalmedicine.code}
                >
                  {dentalmedicine.name}
                </option>
              ))}
            </select>
            <span
              id={`attendee-${index}-dentalmedicine-error`}
              className='text-danger'
            />
          </Col>
        )}
        {(index === 0 || (index && attendee.dentalmedicine === 'S')) && (
          <>
            <Col
              className='mt-2 align-items-end'
              md={6}
            >
              <label
                className='form-label'
                style={{ paddingLeft: 0 }}
                htmlFor={`attendee-${index}-expertise`}
              >
                {t('payment.attendee.expertise')}
                <span className='text-danger'>*</span>
              </label>

              {other ? (
                <input
                  id={`attendee-${index}-expertise`}
                  className='form-control'
                  autoComplete='off'
                  value={attendee.expertise}
                  onChange={(e) => {
                    const { value } = e.target;
                    const newValue = value;
                    fillAttendee('expertise', newValue, index);
                  }}
                />
              ) : (
                <select
                  id={`attendee-${index}-expertise`}
                  className='form-control select-style'
                  autoComplete='off'
                  value={attendee.expertise}
                  onChange={(e) => {
                    const { value } = e.target;
                    // if (value === 'O') {
                    //   setOther(true);
                    //   fillAttendee('expertise', '', index);
                    // } else {
                    fillAttendee('expertise', value, index);
                    // }
                  }}
                >
                  {expertises.map((expertise, index) => (
                    <option
                      key={index}
                      value={expertise.code}
                    >
                      {expertise.name}
                    </option>
                  ))}
                </select>
              )}
              <span
                id={`attendee-${index}-expertise-error`}
                className='text-danger'
              />
            </Col>
          </>
        )}

        {/* <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-howdidyouknow`}
          >
            {t('payment.attendee.howdidyouknow')}<span className='text-danger'>*</span>
          </label>

          <input
            id={`attendee-${index}-howdidyouknow`}
            className='form-control'
            autoComplete='off'
            value={attendee.howdidyouknow}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('howdidyouknow', newValue, index);
            }}
          />
        </Col> */}
        {/* </Row>

      <Row className='mt-2 align-items-end'> */}
        <Col
          className='mt-2 align-items-end'
          md={6}
          hidden={!cart.some((x) => x.order === 3 || x.order === 4)}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-bed`}
          >
            {t('payment.attendee.bed.title')}
            <span className='text-danger'>*</span>
          </label>
          <select
            id={`attendee-${index}-bed`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.bed}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('bed', value, index);
            }}
          >
            {beds.map((bed, index) => (
              <option
                key={index}
                value={bed.code}
              >
                {bed.name}
              </option>
            ))}
          </select>

          <span
            id={`attendee-${index}-bed-error`}
            className='text-danger'
          />
        </Col>
        {/* </Row>
      <Row
        className='mt-2 align-items-end'
        // hidden={attendee.childunder2 !== 'S'}
      > */}
        <Col
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-childunder2`}
          >
            {t('payment.attendee.childunder2.title')}
            <span className='text-danger'>*</span>
          </label>
          <select
            id={`attendee-${index}-childunder2`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.childunder2}
            onChange={(e) => {
              const { value } = e.target;
              if (value === 'N') {
                fillAttendee('childbirthdate', null, index);
                fillAttendee('childname', '', index);
                fillAttendee('childpassport', '', index);
              }
              fillAttendee('childunder2', value, index);
            }}
          >
            {children.map((childunder2, index) => (
              <option
                key={index}
                value={childunder2.code}
              >
                {childunder2.name}
              </option>
            ))}
          </select>

          <span
            id={`attendee-${index}-childunder2-error`}
            className='text-danger'
          />
        </Col>
        <Col
          hidden={attendee.childunder2 !== 'S'}
          className='mt-2 align-items-end'
          md={6}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-childname`}
          >
            {t('payment.attendee.childname')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-childname`}
            className='form-control'
            autoComplete='off'
            value={attendee.childname}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = alphabetMask(value);
              fillAttendee('childname', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-childname-error`}
            className='text-danger'
          />
        </Col>
        <Col
          md={6}
          hidden={attendee.childunder2 !== 'S'}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-childbirthdate`}
          >
            {t('payment.attendee.childbirthdate')}
            <span className='text-danger'>*</span>
          </label>
          <input
            type='date'
            min='1923-01-01'
            max='2024-12-31'
            id={`attendee-${index}-childbirthdate`}
            className='form-control'
            autoComplete='off'
            value={attendee.childbirthdate}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('childbirthdate', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-childbirthdate-error`}
            className='text-danger'
          />
        </Col>
        <Col
          md={6}
          hidden={attendee.childunder2 !== 'S'}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-childpassport`}
          >
            {t('payment.attendee.childpassport')}
            <span className='text-danger'>*</span>
          </label>
          <input
            id={`attendee-${index}-childpassport`}
            className='form-control'
            autoComplete='off'
            value={attendee.childpassport}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('childpassport', newValue, index);
            }}
          />
          <span
            id={`attendee-${index}-childpassport-error`}
            className='text-danger'
          />
        </Col>
        <Col
          className='mt-2 align-items-end'
          md={6}
          hidden={attendee.location === 'OUT' || attendee.location === ''}
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-recomendedby`}
          >
            {t('payment.attendee.recomendedby_title')}
            <span className='text-danger'>*</span>
          </label>
          {/* <input
            id={`attendee-${index}-location`}
            className='form-control'
            autoComplete='off'
            value={attendee.location}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('location', newValue, index);
            }}
          /> */}
          <select
            id={`attendee-${index}-recomendedby`}
            className='form-control select-style'
            autoComplete='off'
            value={attendee.recomendedby}
            onChange={(e) => {
              const { value } = e.target;
              fillAttendee('recomendedby', value, index);
            }}
          >
            {recommendedBy
              .filter(
                (x) => x.country === attendee.location || x.country === 'NDA',
              )
              .map((recommendedBy, index) => (
                <option
                  key={index}
                  value={recommendedBy.code}
                >
                  {recommendedBy.name}
                </option>
              ))}
          </select>
          <span
            id={`attendee-${index}-recomendedby-error`}
            className='text-danger'
          />
        </Col>
      </Row>
      {/* <Row className='mt-2'>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-foodrestriction`}
          >
            {t('payment.attendee.foodrestriction')}
          </label>
          <input
            id={`attendee-${index}-foodrestriction`}
            className='form-control'
            autoComplete='off'
            value={attendee.foodrestriction}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('foodrestriction', newValue, index);
            }}
          />
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-disability`}
          >
            {t('payment.attendee.disability')}
          </label>
          <input
            id={`attendee-${index}-disability`}
            className='form-control'
            autoComplete='off'
            value={attendee.disability}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('disability', newValue, index);
            }}
          />
        </Col>
      </Row> */}

      {/* <Row className='mt-4'>
        <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-changehotelperiod`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.changehotelperiod}
              onChange={(e) => {
                fillAttendee(
                  'changehotelperiod',
                  !attendee.changehotelperiod,
                  index,
                );
                if (!attendee.changehotelperiod === false) {
                  fillAttendee('hotelperiod', '', index);
                }
              }}
            ></input>
            <label className='form-check-label'>
              <p>{t('payment.attendee.changehotelperiod')}</p>
            </label>
          </div>
        </Col>
        <Col md={6}>
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`attendee-${index}-hotelperiod`}
          >
            {t('payment.attendee.hotelperiod')}
            {attendee.changehotelperiod === false ? '' : '*'}
          </label>
          <input
            disabled={!attendee.changehotelperiod}
            id={`attendee-${index}-hotelperiod`}
            className='form-control'
            autoComplete='off'
            value={attendee.hotelperiod}
            onChange={(e) => {
              const { value } = e.target;
              let newValue = value;
              fillAttendee('hotelperiod', newValue, index);
            }}
          />
          <span className='badge'>
            {t('payment.attendee.hotelperiodmessage')}
          </span>
        </Col>
      </Row> */}
      <Row className='mt-4 align-items-end'>
        <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-lgpdterm`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.lgpdterm}
              onChange={(e) => {
                fillAttendee('lgpdterm', !attendee.lgpdterm, index);
              }}
            ></input>
            <label className='form-check-label'>
              <p>
                {t('payment.attendee.lgpdterm')}{' '}
                <a
                  target='_blank'
                  href={t('payment.attendee.lgpdtermurl')}
                >
                  {t('payment.attendee.lgpdtermlink')}
                </a>
                .
              </p>
            </label>
          </div>
          <span
            id={`attendee-${index}-lgpdterm-error`}
            className='text-danger'
          />
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <div className='form-check'>
            <input
              id={`attendee-${index}-communicationterm`}
              className='form-check-input'
              type='checkbox'
              checked={attendee.communicationterm}
              onChange={(e) => {
                fillAttendee(
                  'communicationterm',
                  !attendee.communicationterm,
                  index,
                );
              }}
            ></input>
            <label className='form-check-label'>
              <p className='text-justify'>
                {t('payment.attendee.communicationterm')}
              </p>
            </label>
          </div>
        </Col>
      </Row>
    </>
  );
};
