import Amex from '../assets/images/flags/amex.png';
import Elo from '../assets/images/flags/elo.png';
import Mastercard from '../assets/images/flags/mastercard.png';
import Visa from '../assets/images/flags/visa.png';
import DefaultFlag from '../assets/images/flags/default.png';
import Diners from '../assets/images/flags/diners.png';

export const useCreditCardFlag = () => {
  const creditCardMask = (value) => {
    return value.replace(
      /(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4}).*/,
      (match, group1, group2, group3, group4) => {
        let maskedGroups = '';

        if (group1) {
          maskedGroups += group1 + ' ';
        }
        if (group2) {
          maskedGroups += group2 + ' ';
        }
        if (group3) {
          maskedGroups += group3 + ' ';
        }
        if (group4) {
          maskedGroups += group4;
        }

        return maskedGroups.trim();
      },
    );
  };

  const creditCard6Mask = (value) => {
    return value.replace(
      /(\d{0,4})(\d{0,6})(\d{0,5}).*/,
      (match, group1, group2, group3) => {
        let maskedGroups = '';

        if (group1) {
          maskedGroups += group1 + ' ';
        }
        if (group2) {
          maskedGroups += group2 + ' ';
        }
        if (group3) {
          maskedGroups += group3 + ' ';
        }
        return maskedGroups.trim();
      },
    );
  };

  const flags = {
    AMEX: {
      pattern: /^3[47]\d+$/,
      src: Amex,
      mask: creditCard6Mask,
    },
    MASTERCARD: {
      pattern: /^5[1-5]\d+$/,
      src: Mastercard,
      mask: creditCardMask,
    },
    ELO: {
      pattern:
        /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65\d+$/,
      name: 'ELO',
      src: Elo,
      mask: creditCardMask,
    },
    VISA: {
      pattern: /^4\d+$/,
      src: Visa,
      mask: creditCardMask,
    },

    DINERS: {
      pattern: /^300|^301|^302|^303|^304|^305|^309|^36|^38|^39\d+$/,
      src: Diners,
      mask: creditCardMask,
    },

    DEFAULT: {
      pattern: /^\d+$/,
      src: DefaultFlag,
      mask: creditCardMask,
    },
  };

  return { flags };
};
