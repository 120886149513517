import './styled.css';
import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { useMask } from '../../hooks/useMask';
import { getTickets } from '../../utils/service/product';
import { Information } from '../../components/information';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useVar } from '../../hooks/useVar';
import { useTranslation } from 'react-i18next';
import { calcCart } from '../../utils/service/product';
import TicketDescription from '../../components/ticketDescription';

export const Cart = ({ lng }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const toggle = () => setIsOpenModal(false);
  const [urlTicket, setUrlTicket] = useState('');
  const handleOpenModal = (url) => {
    setUrlTicket(url);
    setIsOpenModal(true);
  };
  const navigate = useNavigate();
  const [t, i18n] = useTranslation();
  const {
    setCart,
    cart,
    firstStep,
    setInstallments,
    setCurrentLanguage,
    currentLanguage,
  } = useContext(TransactionContext);
  const { usdMask } = useMask();
  const { eventId } = useVar();
  const [tickets, setTickets] = useState([]);

  const removeToCart = (id) => {
    const updatedCart = cart.filter((ticket) => ticket.id !== id);
    setCart(updatedCart);
  };
  const addToCart = (ticket) => {
    setCart([...cart, ticket]);
  };

  const addTicketMain = (ticket) => {
    setCart((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.id === ticket.id);
      if (index === -1) {
        return [...newState, ticket];
      }
      return newState;
    });
  };

  const handleNext = async (e) => {
    e.preventDefault();
    navigate('/pagamento');
  };

  const updateCount = (ticket) => {
    setCart((prevState) => {
      const newState = [...prevState];
      const index = newState.findIndex((item) => item.id === ticket.id);
      newState[index].count = ticket.count;
      newState[index] = { ...newState[index] };
      return newState;
    });
  };
  const emptyCart = () => {
    setCart([]);
  };

  const setCount = (value, index) =>
    setTickets((prevState) => {
      const newState = [...prevState];
      const currentCount = newState[index].count;
      const mainTicket = newState[index].details.main;

      if (mainTicket) {
        newState[index] = {
          ...newState[index],
          count: value < 0 ? 0 : 1,
        };

        if (newState[index].count === 0) {
          for (let i = 0; i < newState?.length; i++) {
            const canEnable = newState[i].details.main === true;
            newState[i].details.disabled = !canEnable;
            newState[i] = {
              ...newState[i],
              count: 0,
            };
            emptyCart();
          }
        } else {
          addTicketMain(newState[index]);

          for (let i = 0; i < newState?.length; i++) {
            const canEnable = newState[index].details.tickets.includes(
              newState[i].order,
            );
            newState[i].details.disabled = !canEnable;
            newState[i] = { ...newState[i] };
          }
        }
      } else {
        const mainTicketIndex = newState.findIndex(
          (item) => item.details.main === true && item.count === 1,
        );
        const maxValue =
          newState[mainTicketIndex].details.max[newState[index].order];
        const newCount = currentCount + parseInt(value);
        const canSum = newCount > -1 && newCount <= maxValue;
        if (canSum) {
          newState[index] = {
            ...newState[index],
            count: newCount,
          };
          if (newCount === 0) {
            removeToCart(newState[index].id);
          }
          if (newCount === 1) {
            addToCart(newState[index]);
          }
          if (newCount > 1) {
            updateCount(newState[index]);
          }
        }
      }

      return newState;
    });

  useEffect(() => {
    setCurrentLanguage(lng);
    i18n.changeLanguage(lng);
    document.documentElement.setAttribute('lang', t('language'));
    document.title = t('title');
    firstStep();
    getTickets(eventId).then((data) => {
      const currentTickets = data.map((newTicket) => {
        let available = newTicket?.description2?.split('|');
        available = available.slice(1, available.length);
        return {
          ...newTicket,
          count: 0,
          priceInUSD: parseFloat(newTicket?.description2?.split('|')[0]),
          tickets: available,
          details: JSON.parse(newTicket?.description2),
        };
      });
      // console.log(currentTickets);
      setTickets(currentTickets);

      setCart([]);
    });
  }, [eventId]);

  return (
    <section className='section-cart'>
      <TicketDescription
        isOpen={isOpenModal}
        toggle={toggle}
        urlTicket={urlTicket}
      />
      <Header />
      <Card className='card-cart'>
        <CardBody>
          <Row>
            <Col
              className='home-left-side'
              md={6}
            >
              <Information />
            </Col>
            <Col md={6}>
              <h4 className='mb-3 mt-1'>
                <strong>{t('cart.title-1')}</strong>
              </h4>
              {tickets.map((ticket, index) => {
                const count = tickets?.length ?? 0;
                const half = parseInt(count / 2);
                // console.log('index', index);
                // console.log('count', count);

                if (index <= half) {
                  return (
                    <Row
                      key={index}
                      className='mb-2 border rounded'
                    >
                      <Col>
                        <h5 className='mt-1 mb-0'>
                          <strong>
                            {ticket?.details?.description[currentLanguage]
                              ?.title
                              ? ticket.details.description[currentLanguage]
                                  .title
                              : ticket.description}
                          </strong>
                        </h5>
                        {ticket.details.description[
                          currentLanguage
                        ]?.description?.map((d, index) => (
                          <p
                            key={index}
                            className='td-p'
                          >
                            {d}
                          </p>
                        ))}
                        <p>{usdMask(ticket.details.price)}</p>
                      </Col>
                      <Col className='d-flex justify-content-end'>
                        <div className='ticket-button-wrapper'>
                          <button
                            className={`count-purchase`}
                            onClick={(e) => {
                              setCount(-1, index);
                            }}
                          >
                            -
                          </button>
                          <span>{ticket.count}</span>
                          <button
                            className={`count-purchase ${
                              ticket.details.disabled === true
                                ? 'count-purchase-disabled'
                                : ''
                            }`}
                            onClick={(e) => {
                              setCount(1, index);
                            }}
                            disabled={ticket.details.disabled === true}
                          >
                            +
                          </button>
                        </div>
                      </Col>
                    </Row>
                  );
                }

                if (half + 1 === index) {
                  return (
                    <div key={index}>
                      <h4 className='mb-3 mt-3'>
                        <strong>{t('cart.title-2')}</strong>
                      </h4>
                      <Row className='mb-2 border rounded'>
                        <Col>
                          <h5 className='mt-2 extra'>
                            {ticket?.details?.description[currentLanguage]
                              ?.title
                              ? ticket.details.description[currentLanguage]
                                  .title
                              : ticket.description}
                          </h5>
                          {ticket.details.description[
                            currentLanguage
                          ]?.description?.map((d, index) => (
                            <p
                              key={index}
                              className='td-p'
                            >
                              {d}
                            </p>
                          ))}
                          <p>{usdMask(ticket.details.price)}</p>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                          <div className='ticket-button-wrapper'>
                            <button
                              className={`count-purchase`}
                              onClick={(e) => {
                                setCount(-1, index);
                              }}
                            >
                              -
                            </button>
                            <span>{ticket.count}</span>
                            <button
                              className={`count-purchase ${
                                ticket.details.disabled === true
                                  ? 'count-purchase-disabled'
                                  : ''
                              }`}
                              onClick={(e) => {
                                setCount(1, index);
                              }}
                              disabled={ticket.details.disabled === true}
                            >
                              +
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  );
                }
                return (
                  <Row
                    key={index}
                    className='mb-2 border rounded'
                  >
                    <Col>
                      <h5 className='mt-2 mb-0 extra'>
                        {ticket?.details?.description[currentLanguage]?.title
                          ? ticket.details.description[currentLanguage].title
                          : ticket.description}
                      </h5>
                      {ticket.details.description[
                        currentLanguage
                      ]?.description?.map((d, index) => (
                        <p
                          key={index}
                          className='td-p'
                        >
                          {d}
                        </p>
                      ))}
                      <p>{usdMask(ticket.details.price)}</p>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      <div className='ticket-button-wrapper'>
                        <button
                          className={`count-purchase`}
                          onClick={(e) => {
                            setCount(-1, index);
                          }}
                        >
                          -
                        </button>
                        <span>{ticket.count}</span>
                        <button
                          className={`count-purchase ${
                            ticket.details.disabled === true
                              ? 'count-purchase-disabled'
                              : ''
                          }`}
                          onClick={(e) => {
                            setCount(1, index);
                          }}
                          disabled={ticket.details.disabled === true}
                        >
                          +
                        </button>
                      </div>
                    </Col>
                  </Row>
                );
              })}
              <Row className='row-btn-submit'>
                <button
                  disabled={cart.length === 0}
                  className='btn-submit'
                  onClick={(e) => {
                    handleNext(e);
                  }}
                >
                  {t('cart.buy')}
                </button>
                {/* {cart.length > 0 && (

                )} */}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  );
};
