import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import global_pt from './translations/pt/global.json';
import global_en from './translations/en/global.json';
import global_es from './translations/es/global.json';

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: 'pt',
  resources: {
    pt: {
      translation: global_pt,
    },
    en: {
      translation: global_en,
    },
    es: {
      translation: global_es,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>,
);
