import React from 'react';
import './styled.css';

import Logo from '../../assets/images/Logo.webp';
import Banner from '../../assets/images/banner.webp';
import BannerPT from '../../assets/images/pt_banner.webp';
import BannerES from '../../assets/images/es_banner.webp';
import BannerEN from '../../assets/images/en_banner.webp';
import BRA from '../../assets/images/translations/bra.webp';
import USA from '../../assets/images/translations/usa.webp';
import ESP from '../../assets/images/translations/esp.webp';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { TransactionContext } from '../../contexts/TransactionContext';
export default function Header() {
  const navigate = useNavigate();
  const { setCurrentLanguage, currentLanguage } =
    useContext(TransactionContext);
  const [t, i18n] = useTranslation();
  const handleChange = (language) => {
    setCurrentLanguage(language);
    i18n.changeLanguage(language);
    document.documentElement.setAttribute('lang', t('language'));
    document.title = t('title');
  };

  return (
    <>
      <div className='div-header'>
        <img
          className='header-img'
          alt='Banner do evento'
          src={
            currentLanguage === 'pt'
              ? BannerPT
              : currentLanguage === 'es'
              ? BannerES
              : currentLanguage === 'en'
              ? BannerEN
              : BannerPT
          }
        />
      </div>
    </>
  );
  return (
    <div className='div-header'>
      <div className=''>
        <Row>
          <Col>
            <div className='img-wd'></div>
          </Col>
          {/* {currentLanguage === 'pt' && (
            <Col className='p-1'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleChange('pt');
                }}
              >
                <img
                  alt='Portuguese'
                  src={BRA}
                  className='img-logo'
                />
              </button>
            </Col>
          )}
          {currentLanguage === 'en' && (
            <Col className='p-1'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleChange('en');
                }}
              >
                <img
                  alt='English'
                  src={USA}
                  className='img-logo'
                />
              </button>
            </Col>
          )}
          {currentLanguage === 'es' && (
            <Col className='p-1'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleChange('es');
                }}
              >
                <img
                  alt='Spanish'
                  src={ESP}
                  className='img-logo'
                />
              </button>
            </Col>
          )} */}
        </Row>
      </div>
      <div className='div-banner'>
        <img
          alt='Banner do evento'
          src={
            currentLanguage === 'pt'
              ? BannerPT
              : currentLanguage === 'es'
              ? BannerES
              : currentLanguage === 'en'
              ? BannerEN
              : BannerPT
          }
          className='header-img'
        />
      </div>
    </div>
  );
}
