import React, { createContext, useState, useEffect } from 'react';

export const TransactionContext = createContext();

export const TransactionProvider = ({ children }) => {
  const productDefault = {
    id: '',
    description: '',
    price: 0,
    quantityAvailable: 0,
    description2: '',
    order: 0,
    isHalfPrice: false,
    halfPrice: 0,
    salesStartAt: '',
    salesEndAt: '',
    purchaseMin: 0,
    purchaseMax: 0,
    tag: '',
    installments: 1,
    online: false,
    count: 0,
    tickets: [],
  };

  const [step, setStep] = useState(1);

  const firstStep = () => {
    setStep(1);
  };
  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const toHome = {
    pt: '/ingressos',
    es: '/es/tiquetes',
    en: '/en/tickets',
  };
  const [installments, setInstallments] = useState({});
  const [remainingTime, setRemainingTime] = useState(300);
  const transactionResponseDefault = { success: false, data: null };
  const [selectedProduct, setSelectedProduct] = useState(productDefault);
  const [cart, setCart] = useState([]);
  const [transactionResponse, setTransactionResponse] = useState(
    transactionResponseDefault,
  );
  const [paymentType, setPaymentType] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState('pt');

  const transactionContextValue = {
    productDefault,
    selectedProduct,
    setSelectedProduct,
    transactionResponse,
    setTransactionResponse,
    paymentType,
    setPaymentType,
    remainingTime,
    setRemainingTime,
    cart,
    setCart,
    step,
    firstStep,
    nextStep,
    prevStep,
    installments,
    setInstallments,
    currentLanguage,
    setCurrentLanguage,
    toHome,
  };

  return (
    <TransactionContext.Provider value={transactionContextValue}>
      {children}
    </TransactionContext.Provider>
  );
};
