import './styled.css';
import { Location } from '../location';
import { useTranslation } from 'react-i18next';

export const Information = () => {
  const [t] = useTranslation();
  const list1 = t('cart.description.l1', {
    returnObjects: true,
  });
  const list2 = t('cart.description.l2', {
    returnObjects: true,
  });
  return (
    <>
      {/* <Location /> */}
      <h4 className='mb-3 mt-1'>
        <strong>{t('cart.description.title')}</strong>
      </h4>
      {/* <h2 className='title2'>{t('cart.description.title')}</h2> */}
      <section className='description'>
        {/* <p className='mt-2 mb-2'>{t('cart.description.p1')}</p> */}
        {list1.map((el, index) => (
          <p
            className='mb-2'
            dangerouslySetInnerHTML={{ __html: el }}
            key={index}
          />
          // <p key={index}>{el}</p>
        ))}
        {/* {list2.map((el) => (
          <p>{el}</p>
        ))} */}
        <p>{t('cart.description.p1')}</p>
        <p>
          {t('cart.description.p2')}{' '}
          <a
            target='_blank'
            href={t('cart.description.p2_link.value')}
          >
            {t('cart.description.p2_link.text')}
          </a>
        </p>
        <p>{t('cart.description.p3')}</p>
      </section>
    </>
  );
};
