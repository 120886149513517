import { useTranslation } from 'react-i18next';
export const WarningAdBlock = () => {
  const [t] = useTranslation();
  return (
    <>
      <h3>
        <strong>{t('adblock.title')}</strong>
      </h3>
      <p className='my-alert-p'>{t('adblock.warning1')}</p>
      <p className='my-alert-p'>{t('adblock.warning2')}</p>
    </>
  );
};
