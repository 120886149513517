import axios from 'axios';
import * as Sentry from '@sentry/react';

const getTickets = (eventId) => {
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/events/${eventId}/products`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error?.response?.data);
        Sentry.captureMessage('/events/:eventid/products');
        resolve([]);
      });
  });
};

const calcPromo = async (promoCode, productId) => {
  const encoded = encodeURIComponent(promoCode);
  const config = {
    method: 'GET',
    url: `${process.env.REACT_APP_BASE_URL}/products/${productId}/promo/${encoded}`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data.data))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage('/products/:productid/promo/:promo');
        resolve(null);
      });
  });
};

const calcCart = async (data) => {
  const config = {
    method: 'POST',
    url: `${process.env.REACT_APP_BASE_URL}/products/calc`,
    headers: {
      'content-type': 'application/json',
    },
    data,
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data.data))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.data);
        Sentry.captureMessage('/products/calc');
        resolve(null);
      });
  });
};

const usdbrl = () => {
  const config = {
    method: 'GET',
    url: `https://economia.awesomeapi.com.br/json/last/usd`,
    headers: {
      'content-type': 'application/json',
    },
  };

  return new Promise(async (resolve, reject) => {
    return await axios(config)
      .then((response) => resolve(response.data))
      .catch((error) => {
        Sentry.setContext('request', { config });
        Sentry.setContext('response', error.response.USDBRL);
        Sentry.captureMessage(
          'https://economia.awesomeapi.com.br/json/last/usd',
        );
        resolve(null);
      });
  });
};

export { getTickets, calcPromo, usdbrl, calcCart };
