export const useList = () => {
  const categories = {
    1: 'Gestor de Eventos Corporativos',
    2: 'Gestor de Eventos Associativos',
    3: 'Promotor de Congressos/Feiras',
    4: 'Hotelaria',
    5: 'Agência de Eventos/Live Mkt',
    6: 'Serviços para eventos',
    7: 'Outros',
  };

  const states = [
    'Selecione',
    'Acre (AC)',
    'Alagoas (AL)',
    'Amapá (AP)',
    'Amazonas (AM)',
    'Bahia (BA)',
    'Ceará (CE)',
    'Distrito Federal (DF)',
    'Espírito Santo (ES)',
    'Goiás (GO)',
    'Maranhão (MA)',
    'Mato Grosso (MT)',
    'Mato Grosso do Sul (MS)',
    'Minas Gerais (MG)',
    'Pará (PA)',
    'Paraíba (PB)',
    'Paraná (PR)',
    'Pernambuco (PE)',
    'Piauí (PI)',
    'Rio de Janeiro (RJ)',
    'Rio Grande do Norte (RN)',
    'Rio Grande do Sul (RS)',
    'Rondônia (RO)',
    'Roraima (RR)',
    'Santa Catarina (SC)',
    'São Paulo (SP)',
    'Sergipe (SE)',
    'Tocantins (TO)',
  ];

  return { categories, states };
};
